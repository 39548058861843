import React from 'react';
import PropTypes from 'prop-types';
import { addMinutes, format } from 'date-fns';

const propTypes = {
    id: PropTypes.number,
    open: PropTypes.number,
    count: PropTypes.shape({
        added: PropTypes.number,
        updated: PropTypes.number,
        deleted: PropTypes.number,
        errors: PropTypes.number
    }),
    log: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    modified: PropTypes.instanceOf(Date)
};

const defaultProps = {
    id: undefined,
    open: undefined,
    log: undefined,
    count: undefined,
    modified: undefined
};

class Import extends React.PureComponent {
    render() {
        const { id, count, modified, onClick, open, log } = this.props;

        const formatDaylightSavingsTime = utcDate => {
            const timezoneOffset = utcDate.getTimezoneOffset();
            const isWinter = timezoneOffset === -60;
            const adjustedDate = isWinter ? addMinutes(utcDate, 60) : utcDate;
            return format(adjustedDate, 'YYYY-MM-DD HH:mm');
        };

        return (
            <React.Fragment>
                <tr>
                    <td>{formatDaylightSavingsTime(modified)}</td>
                    <td>{count ? count.added : '-'}</td>
                    <td>{count ? count.updated : '-'}</td>
                    <td>{count ? count.deleted : '-'}</td>
                    <td>{count ? count.errors : '-'}</td>
                    <td>
                        <i
                            className={`fa ${id && id === open ? 'fa-window-close' : 'fa-folder-open'}`}
                            onClick={() => onClick(id)}
                            role="button"
                            tabIndex={0}
                        />
                    </td>
                </tr>
                {id && id === open && (
                    <tr>
                        <td colSpan="6">
                            <pre className="import-summary pre-scrollable mw-100 p-2">{log}</pre>
                        </td>
                    </tr>
                )}
            </React.Fragment>
        );
    }
}

Import.propTypes = propTypes;
Import.defaultProps = defaultProps;

export default Import;
