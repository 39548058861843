import React, { useContext, useState } from 'react';
import { ToastContext } from 'context/ToastContext';
import { useMutation } from '@apollo/client';
import { Button } from '@bytbil/bootstrap-react';

import SYNC_OFFERS_MUTATION from '../mutations/syncOffers';

interface Props {
    dealerCode: string;
}

const SyncDealerOffersBtn = ({ dealerCode }: Props) => {
    const [disableButton, setDisableButton] = useState(false);

    const Toaster = useContext(ToastContext);
    const [syncOffer, { error }] = useMutation(SYNC_OFFERS_MUTATION);

    const handleSyncVehicles = async () => {
        try {
            const confirmSync = window.confirm('Vill du verkligen synca om hela handlarens lager?');

            if (!confirmSync) {
                return undefined;
            }

            setDisableButton(true);
            const {
                data: {
                    syncOffersByDealerCode: { success, message }
                }
            } = await syncOffer({
                variables: { dealerCode },
                optimisticResponse: {
                    syncOffersByDealerCode: {
                        success: true,
                        __typename: '"MutationResponse"',
                        message:
                            'Annonserna håller på att synkas om... observera att detta kan ta en stund beroende på handlarens lagerstorlek...'
                    }
                }
            });

            if (error) {
                Toaster.showToast(message, 'danger', 5000);
            } else if (success) {
                Toaster.showToast(message);
            } else {
                Toaster.showToast(message, 'danger', 5000);
            }

            return undefined;
        } catch (e) {
            Toaster.showToast(
                'Det tog lång tid att få svar från servern - förmodligen kommer annonserna synkas om om ett antal minuter. Vänligen kontrollera lagret innan du testar att synka om igen',
                'warning',
                10000
            );
            return undefined;
        }
    };

    return (
        <div>
            <Button onClick={handleSyncVehicles} disabled={disableButton} color="primary">
                <i className="mr-2 fa fa-refresh fs-1" />
                Synka om alla annonser
            </Button>
        </div>
    );
};

export default SyncDealerOffersBtn;
