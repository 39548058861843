const getControlPanelUrl = () => {
    if (typeof window === 'undefined') {
        return null;
    }

    // Get current URL
    const currentUrl = window.location.href;

    // Set the admin URL based on the current URL
    let CONTROL_PANEL_URL;
    if (currentUrl.includes('https://toolbox.bytbil.com')) {
        CONTROL_PANEL_URL = 'https://control-panel.blocket.io';
    } else {
        CONTROL_PANEL_URL = 'https://control-panel.dev.blocket.cloud';
    }

    return CONTROL_PANEL_URL;
};

export default getControlPanelUrl;
