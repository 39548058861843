import gql from 'graphql-tag';

export default gql`
    mutation SyncOffer($id: String!) {
        syncOfferByRegNoOrAdId(id: $id) {
            success
            message
        }
    }
`;
